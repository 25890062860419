import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts/docs/Layout'
import { connect } from 'react-redux'
// import "katex/dist/katex.min.css"
import {
  onSidebarContentSelected,
  onSetSidebarContentEntry,
  onSetAnchorHide,
  onSetSidebarHide
} from '../actions/layout'
import { getSidebarSelectedKey, getSidebarEntry } from '../store/selectors'
import SEO from '../components/seo'

function Template({
  data, // this prop will be injected by the GraphQL query below.
  onSidebarContentSelected,
  selectedKey,
  onSetSidebarContentEntry,
  sidebarEntry,
  onSetAnchorHide,
  onSetSidebarHide,
  location
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, id } = markdownRemark

  const hideAnchor =
    frontmatter.hideAnchor === null ? false : frontmatter.hideAnchor
  const hideSidebar = frontmatter.sidebar === null ? true : false

  onSetAnchorHide(hideAnchor)
  onSetSidebarHide(hideSidebar)

  if (selectedKey !== id) onSidebarContentSelected(id)
  if (sidebarEntry !== frontmatter.sidebar)
    onSetSidebarContentEntry(frontmatter.sidebar)

  return (
    <>
      <Layout onPostPage={true} location={location}>
        <SEO
          title={frontmatter.seoTitle}
          titleTemplate={data =>
            `%s | ${data.site.siteMetadata.name} Documentation`
          }
          description={frontmatter.seoDescription}
        />
        <div className="blog-post-container">
          <div className="blog-post">
            {frontmatter.showTitle && (
              <h1 className="title is-1" align="left">
                {frontmatter.title}
              </h1>
            )}
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    selectedKey: getSidebarSelectedKey(state),
    sidebarEntry: getSidebarEntry(state)
  }
}

const mapDispatchToProps = {
  onSidebarContentSelected,
  onSetSidebarContentEntry,
  onSetAnchorHide,
  onSetSidebarHide
}

export default connect(mapStateToProps, mapDispatchToProps)(Template)

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      fields {
        slug
      }
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        sidebar
        showTitle
        hideAnchor
        seoTitle
        seoDescription
      }
    }
  }
`
